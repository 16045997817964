<template>
  <b-card v-if="dataReturn" no-body class="card-statistics">
    <b-card-header>
      <b-card-title> {{ $t("message.dashboardAdm.statistic") }}</b-card-title>
      <b-card-text class="font-small-2 mr-25 mb-0">
        {{ localeDate(dataReturn.timestamp) || "Dados não carregados" }}
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col xl="3" sm="6" class="mb-3 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-primary">
                <feather-icon size="24" icon="PackageIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{dataLabel[2]}}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.root.organizations") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="3" sm="6" class="mb-2 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-danger">
                <feather-icon size="24" icon="DatabaseIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{dataLabel[4]}} megabytes</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.root.data") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="3" sm="6" class="mb-2 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-success">
                <feather-icon size="24" icon="AlertTriangleIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{dataLabel[3]}}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.root.pending") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>

        <b-col xl="1" sm="6" class="mb-2 m30">
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" variant="light-info">
                <feather-icon size="24" icon="UserIcon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">{{dataLabel[0]}}</h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t("message.root.users") }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  data() {
    return {
      statisticList: [],

      //CurrentUser
      currentUserObj: store.state.user.userData,
      userData: store.state.user.userData,

      dataReturn: "",
      dataLabel: [],
      
      


    };
  },

  methods: {
    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    async getStatisticsMethod() {
      axios
        .get(`${URL_API}dashboard/statistic-root`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.dataReturn = response.data;
          this.dataLabel = JSON.parse(response.data.chartInfo); // Json to object
        });
    },

    async getOrganizationList(){

      await axios
        .get(`${URL_API}organization/all`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          this.organizationList = response.data;
        });   

    },

  },

  async created() {
    if (this.currentUserObj.email != "root@braxxy.com.br") {
      await this.getStatisticsMethod();
      await this.getOrganizationList();
    }

    
  },
};
</script>

<style>
.m30 {
  margin-left: 30px;
}
</style>
