<template>
  <b-card v-if="organizationList" no-body class="card-company-table">
    <b-table :items="organizationList" responsive :fields="fields" class="mb-0">
      <!-- company -->
      <template #cell(company)="data">
        <div class="d-flex align-items-center">
          <div>
            <div class="font-weight-bolder">
              {{ data.item.company }}
            </div>
            <div class="font-small-2 text-muted">
              {{ data.item.emailCompany }}
            </div>
          </div>
        </div>
      </template>

      <template #cell(category)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.adress }}</span>
        </div>
      </template>

      <template #cell(views)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder mb-25">
            {{ convertData(data.item.dataUsage) || 0 }} {{ " megabytes" }}
          </span>
        </div>
      </template>

      <template #cell(revenue)="data">
        {{ convertData(data.item.dataLimit) || 0 }} {{ " megabytes" }}
      </template>

      <template #cell(sales)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{
            data.item.sales + "%"
          }}</span>
          <feather-icon
            :icon="data.item.loss ? 'TrendingDownIcon' : 'TrendingUpIcon'"
            :class="data.item.loss ? 'text-danger' : 'text-success'"
          />
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable, BAvatar, BImg } from "bootstrap-vue";

import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import axios from "@axios";
import moment from "moment";
import store from "@/store";
export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },

  data() {
    return {
      organizationList: [],

      currentUserObj: store.state.user.userData,
      userData: store.state.user.userData,

      fields: [
        { key: "company", label: "Nome" },
        { key: "category", label: "Endereço" },
        { key: "views", label: "Memória utilizada" },
        { key: "revenue", label: "Memória limite" },
      ],
    };
  },

  async created() {
    await this.getData();
  },

  methods: {
    async getData() {
      await axios
        .get(`${URL_API}organization/all`, {
          headers: getHeader(this.userData),
        })
        .then((response) => {
          for (var i = 0; i < response.data.length; i++) {
            if (response.data[i].status != "Perdeu" && response.data[i].status != "Pendente")
              this.organizationList.push(response.data[i]);
          }
        });
    },

    convertData(data) {
      var fileSizeInKB = 0;
      var fileSizeInMB = 0;

      if (data != undefined && data != null) {
        fileSizeInKB = Number(data) / 1024;
        fileSizeInMB = fileSizeInKB / 1024;
      }

      return fileSizeInMB.toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
