<template>
  <section id="dashboard-administrative">
    <b-row class="match-height">
      <b-col xl="12" md="6">
        <administrative-statistics :data="data.statisticsItems" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col xl="12" md="12">
        <organization-table />
      </b-col>
    </b-row>    
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormCheckbox,
  BCardText,
  BCarousel,
  BCarouselSlide,
} from "bootstrap-vue";

import { getEmployeeData } from "@/auth/utils";
import AdministrativeStatistics from "./AdministrativeStatistics.vue";
import OrganizationTable from "./OrganizationTable.vue";
import axios from "@axios";
import URL_API from "@core/utils/env";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    AdministrativeStatistics,
    OrganizationTable,
    BButton,
    BFormCheckbox,
    BCardText,
    BCarousel,
    BCarouselSlide,
  },
  data() {
    return {
      data: {},

      // User Data
      currentUserObj: store.state.user.userData,

      // Param
      paramData: store.state.user.paramData,

      showWelcomeMessage: false,
    };
  },
  created() {
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      this.data = response.data;

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      const employeeData = getEmployeeData();
      this.data.congratulations.name = employeeData.data.fullName.split(" ")[0];
    });
  },

  mounted() {
    this.openModal();
  },

  methods: {
    openModal() {
      if (this.currentUserObj.tour === true && this.paramData.tour === true) {
        this.$root.$emit("bv::show::modal", "modal-welcome");
      }
    },

    dontShow(value) {
      this.currentUserObj.tour = !value;

      axios({
        method: "put",
        url: `${URL_API}user/tour/${this.currentUserObj.id}`,
        headers: {
          Authorization: "Bearer " + this.currentUserObj.token,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: this.currentUserObj,
      }).then(() => {
        localStorage.setItem("userData", JSON.stringify(this.currentUserObj));
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
